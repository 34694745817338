<template>
    <div v-if="edtPenalties.version==1">
        <b-container v-if="edtPenalties.version==1">
            <PenaltyRow class="even" v-model="edtPenalties.depassementTpsPrepa" :readonly="readonly" @change="emit_change">
                {{ $t("scoring-penalties.overhead_preparation_time")}}
            </PenaltyRow>
            <PenaltyRow class="odd" v-model="edtPenalties.absenceDebutOuFin" :readonly="readonly" @change="emit_change">
                {{ $t("scoring-penalties.missing_start_or_end")}}
            </PenaltyRow>
            <PenaltyRow class="odd" v-if="false" v-model="edtPenalties.absenceDebut" :readonly="readonly" @change="emit_change">
                {{ $t("scoring-penalties.missing_start")}}
            </PenaltyRow>
            <PenaltyRow class="even" v-if="false" v-model="edtPenalties.absenceFin" :readonly="readonly" @change="emit_change">
                {{ $t("scoring-penalties.missing_end") }}
            </PenaltyRow>
            <PenaltyRow class="odd" v-model="edtPenalties.violDureeMini" :readonly="readonly" @change="emit_change">
                {{ $t("scoring-penalties.minimum_duration_violation")}}
            </PenaltyRow>
            <PenaltyRow class="even" v-model="edtPenalties.violDureeMaxi" :readonly="readonly" @change="emit_change">
                <span v-html="$t('scoring-penalties.maximum_duration_violation')"></span>
            </PenaltyRow>
            <PenaltyRow class="odd" v-model="edtPenalties.crash" :readonly="readonly" @change="emit_change">
                {{ $t("scoring-penalties.crash") }} <info-helper>{{$t("scoring-penalties.crash-info-helper") }}</info-helper>
            </PenaltyRow>
            <PenaltyRow class="even" :readonly="readonly" @change="emit_change">
                <b-button variant="outline-secondary" @click="decrementContacts()"  size="" v-if="edtPenalties.contacts > 0"><b>&nbsp;&nbsp;-&nbsp;&nbsp;</b></b-button>
                {{ $t("scoring-penalties.possible_hits") }}
                <span style="font-size:1.3rem">
                    <b-badge href="#" v-show="edtPenalties.contacts > 0" pill variant="primary" @click="decrementContacts()" >{{ edtPenalties.contacts }}</b-badge>
                </span>
                <template #penalty-setter>
                    <b-button variant="outline-secondary" @click="incrementContacts()"  size=""><b>&nbsp;&nbsp;+&nbsp;&nbsp;</b></b-button>
                </template>
            </PenaltyRow>
            <PenaltyRow class="odd" v-model="edtPenalties.absenceMusique" :readonly="readonly" @change="emit_change">
                {{ $t("scoring-penalties.missing_music") }}
            </PenaltyRow>                            
            <PenaltyRow class="even" v-model="edtPenalties.mauvaiseQualiteSon" :readonly="readonly" @change="emit_change">
                {{ $t("scoring-penalties.bad_recording_quality") }}
            </PenaltyRow>
            <PenaltyRow class="odd caution-border" v-model="edtPenalties.sortieTerrain" :readonly="readonly" @change="emit_change">
                {{ $t("scoring-penalties.fly_across_limits")}}<br/>(<b style="color:red;">{{$t("staff-roles.field-director.abbreviate")}}</b>)<info-helper>{{$t("scoring-penalties.fly_accross_limits-info-helper")}}</info-helper>
            </PenaltyRow>
        </b-container>
     </div>
</template>

<script>
import InfoHelper from '@/components/InfoHelper.vue'
import PenaltyRow from './PenaltyDetailHelperRow.vue';

export default{
  components: { InfoHelper, PenaltyRow },
    props:{
        value:{ type:Object, required:true},
        readonly:{ type:Boolean, default: false},
    },
    data(){
        return {
            edtPenalties: this.value,
        }
    },
    methods:{
        incrementContacts(){
            this.edtPenalties.contacts++;
            this.emit_change();
        },
        decrementContacts(){
            if(!this.edtPenalties) this.edtPenalties.contacts = 0; else if(this.edtPenalties.contacts > 0) this.edtPenalties.contacts--;
            this.emit_change();
        },
        emit_change(){
            this.$emit('change');
        }
    }
}
</script>

<style scoped>
    .caution-border{ border: solid 2px red; }
</style>